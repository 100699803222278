// Style
.btn-custom,
.add_cart,
.add_cart:after,
.add_cart i,
.add_favorites,
.graphic_elements,

// Menu Right
ul[data-option="type_car"] li label,
ul[data-option="type_car"] li a,
ul[data-option="type_car"]  li button,

// Header
header .row:first-child,
header .logo,
header .logo:before,
header ul.top_list li a,
header .links_button li a,
header .links_button li button,
header .window_search,
header .window_search a .product_name,
header .window_search .search_footer a,
header .menu_burger,
header .menu_burger:before,
header .mobile_menu_list,
header .mobile_top_list li button,
header .cart_and_review,
header .cart_and_review .cart_footer .cart_btn,
header .cart_and_review.write_a_feedback .cart_body label.form-check-label:before,

// Breadcrumb
nav[aria-label="breadcrumb"] ol .breadcrumb-item a,

// Main
main section.home .links_top a,
main section.home .filter_type,
main section.home .car_type,
main section.sizes ul li a,
main section.single .image_change,
main section.single .product_image_changer ul li label,
main section.single .product_right_content a,
main section.single .add_reviews,

main section.single .tabs .nav-tabs .nav-item .nav-link,
main section.single .tabs .nav-tabs .nav-item .nav-link:after,
main section.catalog .sidebar_filter .filter_header ul li label,
main section.catalog .sidebar_filter .filter_header ul li label svg,
main section.catalog .sidebar_filter .filter_content label:before,
main section.catalog .sidebar_filter .filter_content button,
main section.catalog .top_button_and_links button,
main section.catalog .top_button_and_links a,
main section.catalog .carousel_item,
main section.catalog .catalog_button,
main section.checkout button,
main section.selection a,

// Pagination
.pagination .page-item .page-link,

// Footer
footer a,

// Carousel
.owl-carousel_brand_links .item a,
.owl-carousel_action_products .carousel_item,
.owl-carousel_action_products .item_content,
.owl-carousel_action_products .reviews {
	transition: $transition-base; }
