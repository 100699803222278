@import 'fonts.sass';

main {
	section {
		// Home
		&.home {
			position: relative;
			background-image: url("../img/jpg/home_background.jpg");
			background-position: center bottom;
			background-size: cover;

			.links_top {
				position: relative;
				right: 0;
				left: 0;
				z-index: 1;
				padding-top: 205px;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: center;
				align-content: stretch;

				a {
					position: absolute;
					color: $gray-400;
					font-family: "Open Sans", sans-serif;
					font-size: 13px;
					font-weight: 700;
					font-style: italic;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					align-items: center;
					align-content: stretch;
					width: 240px;

					span {
						position: absolute;
						top: 15px;
						text-decoration: underline; }

					&:before, &:after {
						position: relative; }

					&:first-child {
						justify-content: flex-start;

						span {
							left: 75px; }

						&:before {
							content: url("../img/svg/link_1_before.svg"); } }

					&:last-child {
						justify-content: flex-end;

						span {
							right: 95px; }

						&:after {
							content: url("../img/svg/link_2_after.svg"); } }

					&:hover {
						color: $white; } } }

			.filter_type {
				width: 100%;
				opacity: 0;
				z-index: 10;
				display: none;

				&.active {
					opacity: 1;
					display: block; } }

			.filter {
				bottom: 0;
				z-index: 10;
				display: flex;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: flex-start;
				align-content: stretch;

				.filter_content {
					background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #171b1d 71%, #202528 97%);
					border-radius: $radius;
					min-height: 520px;
					padding: 30px 15px 0 15px;

					.filter_content_title {
						overflow: hidden;

						.advanced_search {
							color: $white;
							font-family: "Open Sans", sans-serif;
							font-size: 13px;
							font-weight: 700;
							text-decoration: underline;
							justify-content: center;
							align-items: center;
							align-content: stretch;
							margin: 20px 0 0 0;
							float: left;
							display: block;
							margin: 2px 0 0 15px;

							&:before {}
							content: url("../img/svg/_advanced_search.svg"); } }

					.name {
						color: $yellow;
						font-family: "Russo One", sans-serif;
						font-size: 25px;
						font-weight: 400;
						line-height: 23px;
						text-transform: uppercase;
						margin-bottom: 15px;
						text-align: right; }

					form {
						.form-group {
							background-color: rgba(0,0,0,0.39);
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							justify-content: flex-end;
							align-items: center;
							align-content: stretch;
							padding: 7px;
							margin-bottom: 10px;

							label {
								color: $yellow;
								font-family: "Open Sans", sans-serif;
								font-size: 13px;
								font-weight: 700;
								font-style: italic;
								line-height: 23px;
								margin: 0 10px 0 0; }

							.form-control {
								max-width: 150px;
								height: 36px;
								border-radius: 0;
								color: $gray-1500;
								font-family: "Open Sans", sans-serif;
								font-size: 13px;
								font-weight: 600;
								line-height: 23px;
								cursor: pointer;
								border: none;

								&:focus {
									box-shadow: none; } } }

						button {
							width: calc(100% - 15px);
							margin: 20px 0 auto 15px;
							display: block; } } } }

			.car_type {
				margin: auto; } }

		// Brand Links
		.brand_links {
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #191d1f 100%);
			padding: 35px 0 30px 0;
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10; }

		// Sizes
		&.sizes {
			padding: 65px 0 30px 0;
			background-color: $gray-1900;
			background-image: url("../img/png/sizes_background.png");

			img {
				position: absolute;
				z-index: 10; }

			h2 {
				color: $gray-500;
				text-align: left;
				margin-bottom: 30px;

				&:after {
					content: '';
					width: 100px;
					height: 1px;
					position: absolute;
					top: 10px;
					left: -120px;
					background-color: $yellow; } }

			strong {
				color: $gray-500;
				max-width: 670px;
				float: left;
				margin-bottom: 60px;

				a {
					color: $yellow; } }

			ul {
				list-style: none;
				padding: 0;
				margin: 0 0 30px 0;
				float: left;

				li {
					color: $yellow;
					font-family: "Russo One", sans-serif;
					font-size: 35px;
					font-weight: 400;
					line-height: 23.06px;
					text-transform: uppercase;

					a {
						color: $gray-500;
						font-family: "Open Sans", sans-serif;
						font-size: 14px;
						font-weight: 600;
						line-height: 29px;
						text-transform: uppercase;
						float: left;
						width: 100%;

						&:hover {
							text-decoration: none;
							color: $yellow; } }

					&:first-child {
						margin-bottom: 12px; } } } }

		// Action Products
		&.action_products {
			padding: 95px 0 0 0; }

		// About Us
		&.about_us {
			padding: 45px 0 70px 0;

			p {
				color: $gray-2000;
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 600;
				line-height: 20.01px; } }

		// Single
		&.single {
			// Product
			.product_left_content {
				position: relative;
				border: 1px solid $gray-600;
				padding: 30px;

				.vendor_code {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					margin: -12px auto 0 auto; }

				.image_change {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: center;
					align-items: center;
					align-content: stretch; } }

			.product_image_changer {
				ul {
					padding: 5px 0;
					margin: 0;
					list-style: none;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-start;
					align-items: center;
					align-content: stretch;

					li {
						padding: 5px;

						&:first-child {
							padding-left: 0; }

						&:last-child {
							padding-right: 0; }

						label {
							border: 1px solid $gray-600;
							max-height: 80px;
							max-width: 80px;
							overflow: hidden;
							width: 100%;
							height: 100%;
							padding: 5px;
							cursor: pointer;
							margin: 0;
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							justify-content: center;
							align-items: center;
							align-content: stretch;

							&:hover, &.active {
								border-color: $yellow; }

							&.active {
								padding: 10px; }

							input {
								display: none; } } } } }

			.product_right_content {
				.top {
					float: left;
					width: 100%;
					border-bottom: 1px solid $gray-600;
					padding-bottom: 18px;

					.rating {
						width: fit-content;
						float: left;
						margin: 5px 9px 0 0; }

					p {
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 14px;
						font-weight: 700;
						float: left;
						margin: 0;

						span {
							color: $green; } }

					a {
						float: left;
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 400;
						text-decoration: underline;
						margin: 1px 0 0 24px;

						&:hover {
							opacity: 0.5; } } }

				.center {
					float: left;
					width: 100%;
					border-bottom: 1px solid $gray-600;

					.product_price {
						padding: 15px 0;
						max-width: 226px;
						align-items: flex-start;

						.availability {
							margin-top: 5px; } } }

				.buttons {
					float: left;
					width: 100%;
					padding: 20px 0 0 0;
					border-bottom: 1px solid $gray-600;

					label {
						width: 100%;
						height: 47px;
						margin-bottom: 20px; } }

				.specifications {
					float: left;
					width: 100%;
					padding: 20px 0 30px 0;

					table {
						width: 100%;

						&:before {
							content: attr(data-table-name);
							color: $gray-1500;
							font-family: "Russo One", sans-serif;
							font-size: 23px;
							font-weight: 400;
							text-transform: uppercase; }

						tr {
							display: block;

							&:nth-child(even) {
								background-color: $gray-200; }

							td {
								color: $gray-2000;
								font-family: "Open Sans", sans-serif;
								font-size: 13px;
								font-weight: 600;
								line-height: 35px;

								img {
									margin-right: 5px; }

								.type {
									&:before {
										margin-top: 9px; } }

								a {
									color: inherit;
									text-decoration: underline;
									line-height: 1.5;

									&:hover {
										opacity: 0.5; } }

								&:first-child {
									width: 233px; }

								&:last-child {
									padding: 0; } } } } } }

			// Tabs
			.tabs {
				margin-top: 40px;
				position: relative;

				#marker_tabs {
					position: absolute;
					top: -100px;
					height: 100px; }

				// Nav Tabs (lg and up)
				.nav-tabs {
					border-color: $gray-900;

					.nav-item {
						.nav-link {
							color: $gray-1000;
							font-family: "Russo One", sans-serif;
							font-size: 35px;
							font-weight: 400;
							line-height: 23px;
							text-transform: uppercase;
							border-top: 0;
							border-right: 0;
							border-bottom: 0;
							border-radius: 0;
							padding: 23px 70px 26px 30px;
							margin: 0 40px 0 0;
							position: relative;
							overflow: hidden;
							border-color: transparent;

							&:after {
								content: url("../img/svg/tab_after.svg");
								position: absolute;
								top: 0;
								right: 0;
								opacity: 0; }

							&.active {
								color: $gray-2000;
								border-color: $gray-900;

								&:after {
									opacity: 1; } } }

						&:first-child {
							.nav-link {
								padding-left: 0;
								border-left: 0; } } } }
				// Nav Tabs Mobile
				.nav-tabs_mobile {
					background-color: transparent;

					ul {
						padding: 0;
						margin: 0;
						list-style: none;

						li {
							label {
								color: $gray-1000;

								input {
									display: none; }

								&.active {
									color: $gray-2000; } } } } } }

			.single_reviews {
				.reviews_header {
					margin-top: 17px;
					border-bottom: solid 1px $gray-600;
					padding-bottom: 15px;

					.add_reviews {
						text-shadow: 0 1px 0 rgba(255, 255, 255, 0.65);
						color: $gray-1500;
						font-family: "Russo One", sans-serif;
						font-size: 15px;
						font-weight: 400;
						line-height: 23px;
						text-transform: uppercase;
						background-color: $yellow;
						border: none;
						border-radius: $radius;
						padding: 12px 45px;
						float: left;

						&:hover {
							color: $white;
							background-color: $green; }

						&:focus {
							outline: none; } }

					.info {
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-weight: 700;
						float: left;

						span {
							color: $green; } }

					.progress_content {
						display: flex;
						flex-wrap: nowrap;
						justify-content: center;
						align-items: center;
						align-content: stretch; }

					.dws-progress-bar {
						float: left; }

					.progress_text {
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-weight: 700;
						float: left;
						margin-top: -10px;

						span {
							color: $yellow; } } }

				table {
					text-align: right;

					tr {
						line-height: 1.8;

						td {
							color: $gray-1500;
							font-family: "Open Sans", sans-serif;
							font-weight: 400;

							.rating {
								margin: 0 9px 0 12px; }

							&:last-child {
								font-weight: 600;
								width: 30px; } } } }

				.reviews_content {
					float: left;
					width: 100%;
					position: relative;

					&:before {
						position: absolute;
						top: 18px;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
						width: 1px;
						background-color: $gray-600; }

					.review {
						border-bottom: solid 1px $gray-600;
						float: left;
						width: 100%;
						padding: 43px 0 22px 0; }
					.assessment {
						float: left;

						span {
							float: left;
							color: $gray-1500;
							font-family: "Open Sans", sans-serif;
							font-size: 13px;
							font-weight: 600;

							&:first-child {
								font-weight: 700; } }

						.rating {
							float: left;
							margin: 4px 9px; } }

					time {
						color: $gray-800;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 400; }

					.review_commentator_and_about {
						margin: 33px 0 17px 0;
						float: left;
						width: 100%;
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 17px;
						font-weight: 600; }

					p {
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 400;
						float: left;
						width: 100%; }

					.about_auto {
						float: left;
						width: 100%;
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 600;

						.recommendation {
							&:before {
								content: url("../img/svg/check_green.svg");
								position: absolute;
								top: 2px;
								left: -9px; } } }

					.review_table {
						float: left;
						width: 100%; }

					button {
						color: $gray-1400;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 700;
						line-height: 23px;
						text-transform: uppercase;
						background-color: $white;
						border: solid 2px $gray-1500;
						padding: 6px 0;
						border-radius: $radius;
						position: relative;
						text-align: center;
						cursor: pointer;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: center;
						align-items: center;
						align-content: stretch;
						width: 100%;
						float: left;
						margin-top: 30px;

						&:before {
							content: attr(data-content); }

						&:hover {
							background-color: $yellow;
							border-color: $yellow; }

						&:focus {
							outline: none; }

						&[aria-expanded="true"] {
							&:before {
								content: attr(data-content-up); } } } } } }
		// Catalog
		&.catalog {
			.sidebar_filter {
				background-color: $gray-1600;
				width: 100%;

				.filter_header {
					ul {
						padding: 0;
						margin: 0;
						list-style: none;
						display: flex;

						li {
							width: 33.3%;
							margin: 0 auto;

							label {
								cursor: pointer;
								width: 100%;
								background-color: $gray-1500;
								margin: 0;

								div.img_wrap {
									height: 46px;
									fill: $gray-600;
									display: flex;
									margin: auto;

									div {
										margin: auto; } }

								div.img_wrap.car1 {
									div {
										width: 71px;
										height: 25px;
										background-image: url("../img/png/filter_car1_w.png"); } }

								div.img_wrap.car2 {
									div {
										width: 56px;
										height: 29px;
										background-image: url("../img/png/filter_car2_w.png"); } }

								div.img_wrap.car3 {
									div {
										width: 51px;
										height: 28px;
										background-image: url("../img/png/filter_car3_w.png"); } }

								input {
									display: none; }

								&:hover {
									div.img_wrap.car1 {
										div {
											background-image: url("../img/png/filter_car1_y.png"); } }

									div.img_wrap.car2 {
										div {
											background-image: url("../img/png/filter_car2_y.png"); } }

									div.img_wrap.car3 {
										div {
											background-image: url("../img/png/filter_car3_y.png"); } } }

								&.active {
									background-color: $yellow;

									div.img_wrap.car1 {
										div {
											background-image: url("../img/png/filter_car1.png"); } }

									div.img_wrap.car2 {
										div {
											background-image: url("../img/png/filter_car2.png"); } }

									div.img_wrap.car3 {
										div {
											background-image: url("../img/png/filter_car3.png"); } } } }

							&:nth-child(2) {
								width: calc(33.3% - 2px); } } } }

				.filter_content {
					padding: 20px;

					label, .name {
						color: $yellow;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 600;
						font-style: italic;
						line-height: 23px;
						cursor: pointer;

						&.form-check-label {
							&:before {
								content: '';
								background-image: url("../img/svg/write_a_feedback_check.svg");
								width: 19px;
								height: 19px;
								float: left;
								margin: 2px 10px 0 0; }

							&.active {
								&:before {
									background-image: url("../img/svg/write_a_feedback_check_checked.svg"); } }

							input {
								display: none; } } }

					select {
						height: 36px;
						border-radius: 0;
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 600;
						line-height: 23px;
						cursor: pointer; }

					.top {
						border-bottom: solid 1px $gray-1300;
						padding-bottom: 15px; }

					.center {
						padding-top: 15px;
						border-bottom: solid 1px $gray-1300;
						padding-bottom: 24px;

						label {
							position: relative;

							&.after_border {
								&:after {
									content: '';
									height: 17px;
									width: 1px;
									background-color: $gray-1300;
									position: absolute;
									top: 0;
									right: 0;
									bottom: -45px;
									left: 0;
									margin: auto; } }


							&.after_border_active.active {
								&:after {
									content: '';
									height: 17px;
									width: 1px;
									background-color: $gray-1300;
									position: absolute;
									top: 0;
									right: 0;
									bottom: -45px;
									left: 0;
									margin: auto; } } }

						ul {
							background-color: $gray-1600;
							padding: 0;
							list-style: none;
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							justify-content: center;
							align-items: center;
							align-content: stretch;
							border: solid 1px $gray-1300;
							min-width: 223px;
							width: max-content;
							height: 26px;
							margin: 4px auto 0 auto;

							li {
								label {
									background-color: $gray-1600;
									padding: 10px 15px 0 15px;
									margin-top: 15px; } } } }

					.range_container {
						padding: 15px;
						border-bottom: solid 1px $gray-1300; }

					.gain {
						padding: 15px 0 0 0;
						border-bottom: solid 1px $gray-1300; }

					.value {
						padding: 15px 0 0 0;
						border-bottom: solid 1px $gray-1300;

						.form-group {
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							justify-content: flex-start;
							align-items: center;
							align-content: stretch;

							select {
								max-width: 84px;
								margin-left: 15px; } } }

					.brands {
						padding: 20px 0 0 0;

						.form-group {
							margin-bottom: 5px; }

						.bootstrap-select {
							width: 100%;
							margin-bottom: 30px;

							.dropdown-toggle {
								height: 36px;
								color: $gray-1500;
								font-family: "Open Sans", sans-serif;
								font-size: 13px;
								font-weight: 600;
								line-height: 23px;
								background-color: $white;
								border: none; } }

						.collapse_button {
							margin-top: 15px;
							padding: 15px 0 0 0;
							background-color: transparent;
							border-top: solid 1px $gray-1300;
							border-right: 0;
							border-bottom: 0;
							border-left: 0;
							text-align: center;
							color: $white;
							font-family: "Russo One", sans-serif;
							font-size: 15px;
							font-weight: 400;
							line-height: 23px;
							text-transform: uppercase;
							width: 100%;
							float: left;

							&:hover {
								color: $yellow; }

							&:focus {
								outline: none; }

							&:before {
								content: attr(data-content); }

							&[aria-expanded="true"] {
								&:before {
									content: attr(data-content-up); } } } }

					.label_white {
						label {
							color: $white;
							font-size: 12px; } } }

				.filter_footer {
					background-color: $white;
					width: 100%;
					float: left;

					.reset_filter {
						color: $gray-1600;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 700;
						font-style: italic;
						line-height: 23px;
						text-align: center;
						width: 100%;
						float: left;
						padding: 15px 0;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: center;
						align-items: center;
						align-content: stretch;
						background-color: transparent;
						border: none;

						&:before {
							content: url("../img/svg/filter_reset.svg");
							width: 19px;
							height: 19px; }

						&:focus {
							outline: none; } } } }

			.catalog_button {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				align-content: stretch;
				width: 100%;
				height: 100%;
				color: $gray-1400;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400;
				line-height: 23px;
				text-transform: uppercase;
				background-color: transparent;
				border: none;

				&:hover {
					opacity: 0.5; }

				&:focus {
					outline: none; }

				span {
					&:first-child {
						order: 1; } }

				span {
					&:last-child {
						order: 3; } }

				&:before {
					content: attr(data-value);
					order: 2;
					font-size: 80px;
					line-height: 0.8;
					margin: 15px 0; }

				&:after {
					content: url("../img/svg/catalog_button.svg");
					order: 4;
					margin-top: 15px; } }

			.top_button_and_links {
				float: right;
				margin-top: -13px;

				button {
					margin: 0 25px 0 0;
					position: relative;
					width: 190px;

					span {
						&:before, &:after {
							position: absolute;
							width: 45px;
							background-size: 46px;
							background-repeat: no-repeat;
							top: 0; }

						&:before {
							content: '';
							background-image: url("../img/svg/btn_catalog_before.svg");
							left: -33px;
							height: 46px; }

						&:after {
							content: '';
							background-image: url("../img/svg/btn_catalog_after.svg");
							height: 57px;
							right: -32px; } } }

				.link_contacts {
					position: relative;
					border-radius: 0 $radius $radius 0;
					padding: 0 40px;

					&:before {
						content: '';
						position: absolute;
						width: 45px;
						background-size: 46px;
						background-repeat: no-repeat;
						top: 0;
						background-image: url("../img/svg/btn_catalog_before.svg");
						left: -33px;
						height: 46px; } }

				button, .link_contacts {
					color: $white;
					font-family: "Russo One", sans-serif;
					font-size: 15px;
					font-weight: 400;
					line-height: 23px;
					text-transform: uppercase;
					background-color: $gray-1600;
					border: none;
					height: 46px;
					float: left;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: center;
					align-items: center;
					align-content: stretch;

					&:focus {
						outline: none; }

					&:hover {
						text-decoration: none;
						color: $yellow; } }

				.dropdown {
					float: left;

					.dropdown-menu {
						top: -2px !important;
						left: -21px !important;
						right: 25px;
						border-radius: 0;
						background-color: $gray-1600;
						border: 0;

						a {
							color: $white;
							font-family: "Russo One", sans-serif;
							font-size: 14px;
							font-weight: 400;
							line-height: 27px;
							float: left;
							padding: 5px 25px;

							&:hover {
								color: #f8c311;
								background-color: $gray-1600; } } } } }

			.filter_select {
				float: right;

				.form-group {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-items: stretch;
					align-content: center;
					float: right; }

				label {
					color: $gray-1600;
					font-family: "Open Sans", sans-serif;
					font-size: 13px;
					font-weight: 700;
					font-style: italic;
					line-height: 23px;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-items: stretch;
					align-content: center;
					margin: 0;

					span {
						margin: 0 15px 0 0; } }

				select {
					width: max-content;
					height: 40px;
					background-color: $gray-200;
					color: $gray-1500;
					font-family: "Open Sans", sans-serif;
					font-size: 13px;
					font-weight: 400;
					line-height: 23px;
					border-radius: 0;
					cursor: pointer;

					&:focus {
						box-shadow: none; } } }

			.pagination {
				float: right;
				margin: 0 -5px 0 0; }

			.sidebar_filter_toggle {
				float: left;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: stretch;
				align-content: center;
				padding: 0 10px;
				height: 40px;
				border: none;
				color: $gray-1500;
				font-family: "Russo One", sans-serif;
				font-size: 12px;
				font-weight: 400;
				line-height: 23px;
				text-transform: uppercase;
				border-radius: $radius;
				background-color: $yellow;

				&:before {
					content: url("../img/svg/sidebar_filter_toggle.svg");
					height: 20px;
					margin: 0 5px 0 0; }

				&:after {
					content: attr(data-content); }

				&[aria-expanded="true"] {
					&:after {
						content: attr(data-content-up); } } } }

		&.checkout {
			padding-bottom: 100px;

			.checkout_header {
				color: $gray-1500;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400;

				.image, .type {
					opacity: 0; } }

			.item {
				margin-bottom: 30px; }

			.image {
				border: 1px solid $gray-600;
				float: left;
				width: 100%;
				padding: 8px;
				min-width: 83px; }

			.name {
				color: $gray-1500;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400; }

			.plus_minus {
				float: left; }

			.type {
				min-width: 85px; }

			.delete {
				background-color: transparent;
				border: none;
				padding: 0;

				&:before {
					content: '';
					width: 29px;
					height: 29px;
					background-image: url("../img/svg/checkout_delete.svg");
					float: left; }

				&:focus {
					outline: none; } }

			.checkout_footer {
				.checkout_footer_border {
					width: 100%;
					height: 1px;
					float: left;
					background-color: $gray-600;
					margin-top: 10px; }

				.value {
					position: relative;
					color: $gray-1500;
					font-family: "Russo One", sans-serif;
					font-weight: 400;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: stretch;
					align-content: center;
					margin-bottom: 24px;

					span {
						font-size: 23px;
						line-height: 0.9; }

					&:before {
						float: left; }

					&:after {
						float: right; }

					&:before, &:after {
						content: '';
						width: 20px;
						height: 1px;
						background-color: $gray-600;
						margin-top: 10px; } }

				.value_container {
					.info {
						margin: 15px 0;
						max-width: 230px; }

					i {
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 400;
						line-height: 18px;
						font-style: normal;
						max-width: 150px;
						margin: auto;
						display: block;
						position: relative;

						&:before {
							content: url("../img/svg/info.svg");
							position: absolute;
							top: 0;
							left: -30px; } } }

				.value_bottom {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-start;
					align-items: flex-end;
					align-content: stretch;
					height: 100%;

					.value {
						&:before, &:after {
							opacity: 0; } } }

				button {
					color: $white;
					font-family: "Russo One", sans-serif;
					font-size: 15px;
					font-weight: 400;
					line-height: 23px;
					text-transform: uppercase;
					height: 47px;
					background-color: $gray-800;
					border: none;
					border-radius: $radius;
					width: 100%;

					&:hover {
						color: $gray-1500;
						background-color: $yellow; }

					&.active {
						color: $gray-1500;
						background-color: $yellow; } }

				.name_form {
					color: $gray-1500;
					font-family: "Russo One", sans-serif;
					font-size: 20px;
					font-weight: 400;
					margin: 30px 0 30px 0;

					&:after {
						content: attr(data-subject);
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 400;
						line-height: 18px;
						float: left;
						width: 100%;
						margin-bottom: 30px; } }

				label {
					color: #313336;
					font-family: "Open Sans", sans-serif;
					font-size: 13px;
					font-weight: 700;

					&.rq {
						&:after {
							content: ' *';
							color: #ff0000; } } }

				input, select, textarea {
					border: solid 1px $gray-600;
					border-radius: 0;
					height: 44px;
					color: $gray-1500;

					&::placeholder {
						color: $gray-1500; }

					&:focus {
						box-shadow: none; } }

				textarea {
					min-height: 100px; } } }

		&.clean {
			p {
				color: $gray-2000;
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 600;
				line-height: 23px;

				img {
					float: left;
					margin: 0 30px 0 0; } } }

		&.comparison {
			.position {
				color: $gray-1500;
				font-family: "Russo One", sans-serif;
				padding-bottom: 15px; }

			ul {
				margin: 32px 0;
				padding: 0;
				list-style: none;

				li {
					float: left;
					width: 100%;

					p {
						color: $gray-1500;
						font-family: "Russo One", sans-serif;
						font-size: 14px;
						font-weight: 400; } }

				&.table_list {
					margin: 32px 0 0 0;

					li {
						p {
							margin: 0; }

						&:nth-child(2) {
							margin: 90px 0; }

						&:nth-child(4) {
							margin: 65px 0 0 0; }

						&:nth-child(5) {
							margin: 30px 0 0 0; }

						&:nth-child(6) {
							margin: 28px 0 0 0; }

						&:nth-child(7) {
							margin: 30px 0 0 0; }

						&:nth-child(8) {
							margin: 53px 0 0 0; }

						&:nth-child(9) {
							margin: 87px 0 0 0; }

						&:nth-child(10) {
							margin: 17px 0 0 0; }

						&:nth-child(11) {
							margin: 15px 0 0 0; }

						&:nth-child(12) {
							margin: 16px 0 0 0; }

						&:nth-child(13) {
							margin: 16px 0 0 0; }

						&:nth-child(14) {
							margin: 16px 0 0 0; }

						&:nth-child(15) {
							margin: 16px 0 0 0; }

						&:nth-child(16) {
							margin: 21px 0 0 0; } } } } }

		&.selection {
			font-family: "Russo One", sans-serif;
			color: $gray-1500;

			h4 {
				font-weight: 400;
				line-height: 30px;
				margin-bottom: 10px;
				text-transform: uppercase; }

			h5 {
				font-size: 15px;
				font-weight: 400;
				text-transform: uppercase; }

			.options {
				max-width: 315px;

				ul {
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;
					align-content: stretch;
					list-style: none;
					padding: 0;
					margin: 0;

					li {
						p {
							font-size: 14px; } } } }

			.border {
				border: none !important;
				width: 100%;
				height: 1px;
				background-color: $gray-600;
				margin: 15px 0 30px 0; }

			.post {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: center;
				align-content: stretch;
				list-style: none;
				max-width: 488px;

				p {
					color: $gray-1500;
					font-family: "Russo One", sans-serif;
					font-size: 13px;
					font-weight: 400; }

				span {
					color: $yellow;
					font-size: 35px;
					font-weight: 400;
					line-height: 23.06px;
					text-transform: uppercase; } }

			.value_container {
				max-width: 488px;
				padding: 25px 0 40px 0;
				border-bottom: solid 1px $gray-600;
				margin-bottom: 30px;

				&.left_right {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-between;
					align-items: center;
					align-content: stretch;
					padding: 0;
					border: none;
					margin: 0;

					.value {
						margin: 0; } } }

			.value {
				text-shadow: 0 1px 0 rgba(255, 255, 255, 0.65);
				font-size: 15px;
				font-weight: 400;
				line-height: 23px;
				text-transform: uppercase;
				width: 142px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				align-content: stretch;
				height: 29px;
				margin: 0 auto 9px auto;
				position: relative; }

			a.value {
				color: $gray-1500;
				width: max-content;

				&:hover {
					color: $yellow;
					text-decoration: none; } }

			.item {
				&.item_left {
					.value {
						border: solid 2px $yellow;
						border-radius: $radius;

						&:after {
							content: attr(data-after);
							height: 23px;
							font-size: 11px;
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							justify-content: center;
							align-items: center;
							align-content: stretch;
							background-color: $yellow;
							position: absolute;
							border-radius: $radius; } } } }

			input, select, textarea {
				border: solid 1px $gray-600;
				border-radius: 0;
				height: 44px;
				color: $gray-1500;

				&::placeholder {
					color: $gray-1500; }

				&:focus {
					box-shadow: none;
					color: $gray-1500; } } } } }
.comparison {
	.owl-item {
		.image {
			img {
				max-width: 185px;
				max-height: 185px; } } } }

.product_left_content {
	.image_change {
		img {
			max-height: 490px; } } }

// Rating
.rating {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

	&:after {
		color: $gray-1500;
		font-family: "Open Sans", sans-serif;
		font-size: 13px;
		font-weight: 600;
		margin: 0 0 0 2px; }

	.progress {
		height: 11px;
		width: 65px;
		position: relative;
		background-color: $gray-1500;

		.mask {
			background-image: url("../img/svg/rating.svg");
			height: 11px;
			width: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-position: 0 -1px;
			background-size: 13px; }

		.scale {
			background-color: $yellow;
			height: 11px; } } }

.tier_availability_pretext {
	display: none; }

#accordion_mobile_view_top {
	.card-body {
		text-align: center;
		font-size: 18px;
		line-height: 28px; } }

#callback-modal {
	button {
		font-family: "Russo One", sans-serif;
		font-size: 15px;
		font-weight: 400;
		line-height: 23px;
		text-transform: uppercase;
		height: 47px;
		border: none;
		border-radius: $radius;
		width: 100%;
		color: $gray-1500;
		background-color: $yellow; }

	label {
		color: #313336;
		font-family: "Open Sans", sans-serif;
		font-size: 13px;
		font-weight: 700;

		&.rq {
			&:after {
				content: ' *';
				color: #ff0000; } } }

	.modal-close {
		width: 30px;
		padding: 0;
		height: 30px; } }

.callback_hover {
	display: none; }

.carousel_item {
	&:hover {
		.callback_hover {
			display: block; } } }

.single_reviews {
	a {
		color: $gray-2000;
		border-bottom: solid 1px transparent;

		&:hover {
			text-decoration: none;
			border-color: $gray-2000; } } }
