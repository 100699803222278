$md: 992px;
/* Medium devices (desktops, 992px and up) */
@media (min-width: $md) {
	.product_price {
		.price:after {
			content: attr(data-info); }
		.availability {
			display: block; } }

	.add_favorites {
		display: flex; }
	// Style
	h2 {
		line-height: 23px; }

	nav {
		&[aria-label="breadcrumb"] {
			padding-top: 163px; } }

	// Graphic Element
	.graphic_elements {
		right: 0;
		bottom: 155px;
		left: 0;

		// Type 1
		&.type_1 {
			.left {
				.bottom {
					&:before {
						width: 90px; }

					&:after {
						left: 90px; } } }

			.right {
				bottom: 80px;

				.bottom {
					&:before {
						width: 90px; }

					&:after {
						right: 90px; } } } }

		// Type 2
		&.type_2 {
			.left {
				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 30px; }

					&:after {
						left: 30px; } } }

			.right {
				bottom: 40px;

				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 280px; }

					&:after {
						right: 280px; } } } }

		// Type 3
		&.type_3 {
			.left {
				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 70px; }

					&:after {
						left: 70px; } } }

			.right {
				bottom: 145px;

				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 280px; }

					&:after {
						right: 280px; } } } } }

	// Header
	header {
		.row:first-child {
			padding: 3rem 0 5rem 0; }

		&.slim {
			.logo {
				bottom: -75px; } }

		// List
		ul.top_list {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			align-content: stretch;

			li {
				a, i {
					padding: 20px 10px 22px 10px; }

				&:first-child {
					a {
						padding-left: 0; } }

				&:last-child {
					a {
						padding-right: 0; } } } }

		// Logo
		.logo {
			&:before {
				height: 123px; } }

		// Link Buttons
		.links_button {
			li {
				&:first-child {
					a {
						margin: 0 10px; } } } } }

	// Main
	main {
		// Carousel
		.owl-carousel_brand_links {
			.owl-stage-outer {
				max-width: 100%; }

			.item {
				a {
					padding: 0 20px; } } }
		.owl-carousel_action_products {
			.owl-item {
				padding: 5px; } }
		section {
			// Home
			&.home {
				.filter {
					top: 150px; }

				.car_type {
					bottom: 95px; } }

			// Sizes
			&.sizes {
				img {
					max-width: 200%;
					bottom: -80px;
					left: -170px; } }
			// Single
			&.single {
				.single_reviews {
					.reviews_header {
						.info {
							margin: 10px 22px;
							max-width: 100%;
							line-height: 1.5;
							text-align: left;
							float: left; }

						.progress_content {
							margin-top: 0; }

						.progress_text {
							font-size: 12px; } }

					table {
						tr {
							td {
								font-size: 13px; } } }

					.reviews_content {
						time {
							float: right; } } } }
			// Catalog
			&.catalog {
				.catalog_pagination_bottom {
					float: right;
					margin: 0 -5px 0 0; } }
			// Checkout
			&.checkout {
				.image {
					max-width: 83px; }

				.name {
					max-width: 100%; }

				.plus_minus {
					width: auto;

					button, label {
						width: 26px; }

					input {
						width: 26px; } } }
			// Comparison
			&.comparison {
				.table_list {
					li {
						&:nth-child(3) {
							margin: 35px 0 0 0; } } } }

			// Selection
			&.selection {
				.item {
					&.item_left {
						.value {
							width: 142px;
							padding-right: 19px;
							margin: 0 auto 9px auto;

							&:after {
								width: 56px;
								right: -30px;
								bottom: 0; } } } } } } }

	// Footer
	footer {
		// List
		ul {
			margin-bottom: 0; }

		// Copyright
		.copyright {
			border-top: solid 1px #42474b;
			margin: 50px 0 0 0;
			padding: 30px 0;
			text-align: center; } } }
