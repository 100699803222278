$lg: 1200px;
/* Large devices (large desktops, 1200px and up) */
@media (min-width: $lg) {
	// Style
	.graphic_elements {
		// Type 2
		&.type_2 {
			.right {
				bottom: 60px; } } }
	// Main
	main {
		section {
			// Sizes
			&.sizes {
				img {
					max-width: 300%;
					bottom: -100px;
					left: -125px; } }

			// Single
			&.single {
				.single_reviews {
					.reviews_header {
						.progress_text {
							font-size: 18px; } } } }

			// Comparison
			&.comparison {
				.table_list {
					li {
						&:nth-child(3) {
							margin: 55px 0 0 0; } } } } } }

	.tier_availability_pretext {
		display: inline-block; } }
