header {
	background-image: url("../img/svg/nav_img.svg");
	background-color: $gray-2000;
	box-shadow: $shadow;
	position: fixed;
	width: 100%;
	z-index: 20;

	.reviews_link {
		position: absolute;
		top: 0;
		bottom: 0;
		height: 100vh;
		color: #2b363c;
		font-family: "Open Sans", sans-serif;
		font-size: 15px;
		font-weight: 700;
		line-height: 23px;
		text-transform: uppercase;

		a {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 60px;
			height: 43px;

			&:before {
				content: url("../img/svg/reviews_link.svg");
				position: absolute;
				top: -90px; } } }

	.row:first-child {
		overflow: hidden; }

	&.slim {
		.logo {
			&:before {
				max-width: 273px;
				background-image: url("../img/svg/logo_slim.svg"); } }

		ul.top_list.top_list_focus {
			display: flex; }

		form {
			&.search {
				display: none; } } }

	ul.top_list.top_list_focus {
		display: none; }


	// List
	ul.top_list {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			a, i {
				color: $white;
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 400;
				font-style: normal;
				line-height: 23px;
				float: left;

				img {
					margin: -2px 10px 0 0; }

				span {
					color: #b0b1b1; } }

			a {
				&:hover {
					text-decoration: none;
					color: $yellow; } } }

		&.list_left {
			justify-content: flex-start; }

		&.list_right {
			justify-content: flex-end; } }

	// Logo
	.logo {
		position: absolute;
		right: 0;
		bottom: -32px;
		left: 0;

		&:before {
			content: '';
			min-width: 100px;
			max-width: 273px;
			width: 100%;
			background-image: url("../img/svg/logo.svg");
			background-size: contain;
			background-repeat: no-repeat;
			display: block;
			margin: auto; } }

	// Form
	form {
		label {
			margin: 0;
			width: 100%;
			position: relative;

			input {
				width: 100%;
				height: 37px;
				float: left;
				background-color: $gray-1600;
				border: none;
				padding: 0 23px;
				color: #d3d3d3;
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 400;
				line-height: 23px;
				font-style: italic;
				margin-bottom: 2px;
				position: relative;
				z-index: 10;
				border-radius: $radius 0 0 $radius;

				&:focus {
					outline: none; } } }

		&.search {
			label {
				&:after {
					content: url("../img/svg/search_after.svg");
					position: absolute;
					right: -17px; } } } }

	// Link Buttons
	.links_button {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
		align-items: center;
		align-content: stretch;

		#favorites_count_text {
			font-style: normal; }

		li {

			a, button {
				position: relative;
				background-color: $yellow;
				padding: 7px 5px;
				text-shadow: 0 1px 0 rgba(255, 255, 255, 0.65);
				color: $gray-2200;
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 400;
				line-height: 23px;
				float: left;
				border: none;

				&:hover {
					text-decoration: none;
					opacity: 0.5; }

				&:focus {
					outline: none; }

				&:before, &:after {
					position: absolute;
					height: 37px;
					width: 37px;
					top: 0; } }

			&:first-child {
				a {
					&:before {
						content: '';
						background-image: url("../img/svg/btn_header_before.svg");
						left: -27px; }

					&:after {
						content: '';
						background-image: url("../img/svg/btn_header_after.svg");
						right: -27px; }

					span {
						width: 50px;
						height: 20px;
						float: left;

						&:before, &:after {
							position: absolute; }

						&:before {
							content: url("../img/svg/btn_header_span.svg");
							top: 0;
							left: 15px; }

						&:after {
							content: '-';
							left: 40px; } } } }

			&:last-child {
				button {
					font-weight: 700;
					margin-left: 15px;
					border-radius: 0 $radius $radius 0;

					&:before {
						content: '';
						background-image: url("../img/svg/btn_header_before.svg");
						left: -27px; }

					img {
						padding-right: 5px; }

					&:after {
						content: url("../img/svg/btn_header_cart_after.svg");
						position: relative;
						top: 1px;
						margin: 0 2px; } } } } }

	// Window Search
	.window_search {
		position: absolute;
		top: 57px;
		width: 408px;
		background-color: $gray-1600;
		box-shadow: $shadow;
		opacity: 0;

		&:before {
			content: '';
			width: 30px;
			height: 30px;
			position: absolute;
			background-color: $gray-1600;
			transform: rotate(45deg);
			top: -5px;
			left: 50px; }

		.post {
			border-bottom: solid 1px $gray-1300;
			padding: 15px;

			.row {
				&:first-child {
					height: auto;
					padding: 0;
					opacity: 1; } }

			.product_name {
				color: $white;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400;
				max-width: 219px;
				margin: -4px 0 4px 0; }

			.product_price {
				color: $white;
				font-family: "Open Sans", sans-serif;
				font-size: 23px;
				font-weight: 700;
				line-height: 23px;

				&:after {
					content: attr(data-info);
					color: $white;
					font-size: 12px;
					font-weight: 400;
					line-height: 23px;
					float: left;
					width: 100%; } }

			.type {
				color: $white; }

			a {
				&:hover {
					text-decoration: none;

					.product_name {
						color: $yellow; } } } }

		.search_footer {
			a {
				padding: 23px 0;
				display: block;
				color: $white;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400;
				text-align: center;

				&:after {
					content: attr(data-value);
					padding-left: 5px; }

				&:hover {
					text-decoration: none;
					color: $yellow; } } } }

	// Cart
	.cart_and_review {
		position: absolute;
		top: 0;
		right: -520px;
		bottom: 0;
		height: 100vh;
		background-color: $white;
		box-shadow: $shadow;
		max-width: 520px;
		min-width: 320px;
		width: 100%;
		z-index: 10;
		opacity: 0;

		.cart_header {
			position: relative;
			color: $gray-2000;
			font-family: "Russo One", sans-serif;
			font-size: 20px;
			font-weight: 400;
			line-height: 23px;
			text-transform: uppercase;
			border-bottom: solid 1px $gray-600;
			margin: 30px 30px 0 30px;
			padding-bottom: 30px;

			button {
				background-color: transparent;
				border: none;
				padding: 0;
				opacity: 0.5;
				margin-left: 23px;
				top: 0;
				right: 0;

				&:focus {
					outline: none; }

				&:before {
					content: url("../img/svg/cart_close.svg");
					height: 18px;
					float: left; } } }

		.cart_body {
			overflow-x: hidden;
			overflow-y: scroll;
			height: calc(100vh - 280px);

			&::-webkit-scrollbar {
				display: none; }

			.post {
				padding: 30px 0;
				border-bottom: solid 1px $gray-600;

				.row {
					&:first-child {
						height: auto;
						padding: 0;
						opacity: 1;
						overflow: initial; } }

				.post_image {
					border: solid 1px $gray-600;
					position: relative;

					.remove {
						background-color: transparent;
						border: none;
						padding: 5px;
						position: absolute;
						top: -15px;
						left: -15px;

						&:before {
							content: url("../img/png/remove_icon1@1X_2.png");
							height: 20px;
							float: left;
							color: #ffffff; } }

					img {
						margin: auto;
						display: block; } }

				.product_name {
					color: $gray-1500;
					font-family: "Russo One", sans-serif;
					font-size: 14px;
					font-weight: 400; }

				.vendor_code {
					margin-top: 15px;
					float: left;
					width: 140px; }

				.type {
					margin-top: 17px;
					float: right;
					color: $gray-1500; }

				.price {
					color: $gray-1500;
					font-family: "Russo One", sans-serif;
					font-size: 16px;
					font-weight: 400;
					line-height: 23px;
					float: right;
					margin-top: 21px; } } }

		.cart_footer {
			.row {
				&:first-child {
					height: auto;
					padding: 0;
					opacity: 1; } }

			.total_price {
				border-bottom: solid 1px $gray-600;
				color: $gray-1500;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400;
				width: 100%;
				text-align: right;

				span {
					font-size: 23px;
					font-weight: 400; } }

			.cart_btn {
				float: left;
				font-family: "Russo One", sans-serif;
				font-weight: 400;
				line-height: 23px;
				border: none;
				border-radius: $radius;
				padding: 12px 0;
				text-align: center;

				&:focus {
					outline: none; }

				&:hover {
					text-decoration: none;
					opacity: 0.5; }

				&.cart_btn_order {
					background-color: $yellow;
					color: $gray-1500;
					text-shadow: 0 1px 0 rgba(255, 255, 255, 0.65);
					font-size: 15px;
					text-transform: uppercase; }

				&.cart_btn_shopping {
					background-color: $gray-1400;
					color: $yellow;
					font-size: 13px;
					display: flex;
					flex-direction: row-reverse;
					flex-wrap: nowrap;
					justify-content: center;
					align-items: center;
					align-content: stretch;

					&:after {
						content: url("../img/svg/shopping.svg");
						height: 20px;
						margin: 0 13px 0 0; } } } }

		&.write_a_feedback {
			.cart_header {
				float: left;
				border-bottom: none;

				span {
					float: left;
					width: 100%;
					font-size: 14px;
					margin-top: 16px; } }

			.cart_body {
				height: calc(100vh - 190px);

				form {
					label, .name_rating {
						color: #313336;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 700;
						margin-bottom: 12px;

						&:after {
							content: '*';
							color: $red;
							margin-left: 5px; }

						&.form-check-label {
							color: $gray-2000;
							font-family: "Russo One",sans-serif;
							font-size: 14px;
							font-weight: 400;
							line-height: 23px;
							text-transform: uppercase;
							cursor: pointer;
							margin-top: 30px;

							&:before {
								content: '';
								background-image: url("../img/svg/write_a_feedback_check.svg");
								float: left;
								height: 19px;
								width: 19px;
								margin: 0 6px 0 0; }

							&.active {
								&:before {
									background-image: url("../img/svg/write_a_feedback_check_checked.svg"); } }

							&:after {
								content: ''; }

							input {
								display: none; } } }

					input {
						height: 44px; }

					input, textarea {
						border: 1px solid #dcdcdc;
						border-radius: 0;
						color: $gray-1500;

						&:focus {
							box-shadow: none; } }

					.radio_rating {
						padding: 0;
						list-style: none;
						float: left;
						position: relative;
						width: fit-content;

						&:after {
							content: attr(data-value);
							color: $gray-1500;
							font-family: "Open Sans", sans-serif;
							font-size: 13px;
							font-weight: 400;
							position: absolute;
							right: -35px; }

						li {
							display: block;
							float: right;
							background-image: url("../img/svg/rating.svg");
							background-repeat: no-repeat;
							background-position: 0 -1px;
							background-color: $gray-1500;

							&:hover, &:hover ~ li {
								background-color: $yellow; }

							&.active, &.active ~ li {
								background-color: $yellow; }

							label {
								float: left;
								margin: 0;
								cursor: pointer;

								&:after {
									content: ''; }
								input {
									opacity: 0; } } } }

					.info {
						color: $gray-1500;
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 400;
						float: left; } } } } }

	// Menu
	.menu_burger {
		padding: 0;
		margin: 0;
		background-color: transparent;
		border: none;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		align-content: stretch;
		height: 100%;

		&:before {
			content: '';
			background-image: url("../img/svg/menu.svg");
			background-repeat: no-repeat;
			width: 23px;
			height: 23px;
			position: absolute; }

		&.active {
			background-color: $gray-2000;

			&:before {
				background-image: url("../img/svg/close_menu.svg"); } } }

	.mobile_menu_list {
		position: absolute;
		top: 40px;
		right: 0;
		left: 0;
		background-color: $gray-2000;
		overflow: hidden;
		height: 0;

		ul {
			padding: 50px 55px;
			margin: 0;
			list-style: none;

			li {
				a {
					color: $white;
					font-family: "Russo One", sans-serif;
					font-size: 15px;
					font-weight: 400;
					line-height: 46px;
					text-transform: uppercase;

					input {
						display: none; }

					&.active {
						color: $yellow; } } } }

		&.active {
			height: 422px; } }

	.mobile_top_list {
		list-style: none;
		padding: 0;
		margin: 0;
		height: 100%;

		li {
			height: 100%;
			padding: 0;

			a, button {
				padding: 5px 0;
				background-color: transparent;
				border-top: none;
				border-right: solid 1px $gray-1200;
				border-bottom: none;
				border-left: none;
				height: 100%;
				width: 100%;
				margin: auto;
				text-align: center;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				align-content: stretch;

				&.logo_mobile {
					border-left: solid 1px $gray-1200;

					&:before {
						content: url("../img/svg/logo_mobile.svg"); } }

				&[aria-expanded="true"] {
					background-color: $gray-1700; }

				&:focus {
					outline: none; } }

			#m_contacts {
				&:before {
					content: url("../img/svg/mobile_view_top_button_1.svg"); } }

			#m_search {
				&:before {
					content: url("../img/svg/mobile_view_top_button_2.svg");
					height: 20px; } }

			#m_cart {
				&:before {
					content: url("../img/svg/mobile_view_top_button_3.svg");
					height: 20px; } } } }

	.accordion {
		background-color: $gray-1700;
		width: 100%;

		form {
			label {
				&:after {
					display: none; }

				input {
					margin: 15px 0 10px 0; } } } } }
