$xl: 1440px;
/* Extra Large devices (large desktops, 1440px and up) */
@media (min-width: $xl) {
	// Main
	main {
		section {
			// Home
			&.home {
				.links_top {
					a {
						&:first-child {
							left: -100px; }

						&:last-child {
							right: -100px; } } } } } } }
