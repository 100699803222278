footer {
	background-color: $gray-2000;

	// Logo
	.logo {
		&:before {
			content: '';
			min-width: 100px;
			max-width: 153px;
			width: 100%;
			height: 66px;
			background-image: url("../img/svg/logo.svg");
			background-size: contain;
			background-repeat: no-repeat;
			display: block; } }

	// list
	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			a, i {
				color: $white;
				font-family: "Open Sans", sans-serif;
				font-size: 14px;
				font-weight: 400;
				line-height: 26px;
				position: relative;
				font-style: normal;

				img {
					top: 5px;
					left: -20px; } }

			a {
				&:hover {
					text-decoration: none;
					color: $yellow; } } } }

	// Copyright
	.copyright {
		color: $white;
		font-family: "Open Sans", sans-serif;
		font-size: 13px;
		font-weight: 400;
		line-height: 23px;
		display: block;

		a {
			color: $white;

			&:hover {
				color: $yellow;
				text-decoration: none; } } } }
