$xs_horizontal: 568px;
/* Small devices (phones, 568px and up) */
@media (min-width: $xs_horizontal) {
	// Main
	main {
		section {
			// Comparison
			&.comparison {
				.position {
					display: none; } } } } }
