.owl-carousel {
	.owl-stage-outer {
		z-index: 10;
		margin: auto; }

	.owl-nav {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		align-content: space-around;

		button {
			z-index: 15;
			width: 38px;
			height: 38px;

			span {
				display: none; }

			&:before {
				padding: 0; }

			&:focus {
				outline: none; } }

		.owl-prev {
			&:before {
				content: url("../img/svg/carousel_left.svg"); } }

		.owl-next {
			&:before {
				content: url("../img/svg/carousel_right.svg"); } } } }

// Brand Links
.owl-carousel_brand_links {
	max-width: 1145px;
	padding: 0 15px;
	margin: auto;

	.item {
		width: 100%;

		a {
			border: solid 1px transparent;
			height: 63px;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			align-content: space-around;

			&:hover {
				border-color: $yellow; } } }

	.owl-nav {
		.owl-prev {
			&:before {
				content: url("../img/svg/pagination_prev.svg"); } }

		.owl-next {
			&:before {
				content: url("../img/svg/pagination_next.svg"); } } } }

// Action
.owl-carousel_action_products {
	.owl-stage-outer {
		padding-bottom: 58px; }

	&.products_container {
		.carousel_item {
			margin-bottom: 0; } } }

.products_container {
	.carousel_item {
		border: 1px solid $gray-600;
		float: left;
		position: relative;
		width: 100%;
		margin-bottom: 40px;

		.item_header {
			padding: 20px 15px 30px 15px;

			img {
				display: block;
				margin: auto;
				max-height: 216px;
				max-width: 216px; } }

		.item_content {
			padding: 16px 15px 6px 15px;
			position: relative;
			border-top: solid 1px $gray-600;
			min-height: 104px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			align-content: stretch;

			.vendor_code {
				position: absolute;
				top: -12px;
				right: 0;
				left: 0;
				margin: auto; } }

		.product_info {
			padding: 0 15px 0 15px;
			border-top: solid 1px $gray-600;
			border-bottom: solid 1px $gray-600;
			float: left;
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			align-content: stretch;

			.content_left, .content_right {
				width: 50%; }

			.content_left {
				float: left;
				max-width: 104px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
				align-content: stretch;
				color: $gray-1500;
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 600;

				&:before {
					width: 19px;
					height: 19px;
					margin: 0 8px 0 0; }

				&:after {
					content: ' ' attr(data-text); }

				&[data-type='1'] {
					&:before {
						content: url("../img/svg/product_type_1.svg"); } }

				&[data-type='2'] {
					&:before {
						content: url("../img/svg/product_type_2.svg"); } }

				&[data-type='3'] {
					&:before {
						content: url("../img/svg/product_type_3.svg"); } } }

			.content_border {
				height: 55px;

				&:before {
					content: url("../img/svg/border.svg");
					display: block; } }

			.content_right {
				float: right;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				align-content: stretch;

				.rating {
					&:after {
						content: '- ' attr(data-after); } }

				.reviews {
					color: $gray-1500;
					font-family: "Open Sans", sans-serif;
					font-size: 13px;
					font-weight: 600;
					text-decoration: underline;

					&:hover {
						opacity: 0.5; } } } }

		.product_price {
			padding: 13px 15px 10px 15px;
			align-items: center; }

		.item_footer {
			padding: 17px 15px 21px 15px;

			.add_cart {
				width: 100%;
				margin: 0 0 3px 0; }

			.add_favorites {
				width: 100%;
				margin: 0; } }

		&:after {
			content: url("../img/svg/carousel_item_after.svg");
			position: absolute;
			bottom: -58px;
			left: 0; }

		&:hover {
			border-color: $gray-1400;

			.item_content {
				border-color: $gray-1400; } } } }

// Comparison
.owl-carousel_comparison {
	.owl-nav {
		button {
			width: 15px;
			position: absolute;
			top: 135px; }

		.owl-prev {
			left: 0;

			&:before {
				content: url("../img/svg/comparison_left.svg"); } }

		.owl-next {
			right: 0;

			&:before {
				content: url("../img/svg/comparison_right.svg"); } } }

	.item {
		border: solid 1px $gray-600;
		padding: 30px 25px;
		margin-bottom: 15px;

		.item_header {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			align-content: stretch;
			text-align: center; }

		.vendor_code {
			width: 100%;
			background-position: center;
			margin-bottom: 35px; }

		.image {
			border: solid 1px $gray-600;
			margin-bottom: 30px; }

		.name {
			color: $gray-1500;
			font-family: "Russo One", sans-serif;
			font-size: 15px;
			font-weight: 400;
			margin-bottom: 23px;
			height: 65px;
			max-height: 65px;
			overflow: hidden; }

		.price {
			color: $gray-1500;
			font-family: "Russo One", sans-serif;
			font-size: 23px;
			font-weight: 400;
			line-height: 23px;
			margin-bottom: 25px; }

		.type {
			margin-bottom: 30px; }

		.country {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			align-content: stretch;
			margin-bottom: 30px;

			img {
				width: 24px;
				float: left;
				margin: 0 7px 0 0; }

			&:after {
				content: attr(data-after);
				color: $gray-1500;
				font-family: "Open Sans";
				font-size: 13px;
				font-weight: 600; } }

		.rating {
			&:after {
				content: '- ' attr(data-after); } }

		.reviews {
			color: $gray-1500;
			font-family: "Open Sans", sans-serif;
			font-size: 13px;
			font-weight: 600;
			text-decoration: underline;
			margin: 10px 0 25px 0; }

		.availability {
			color: $gray-1500;
			font-family: "Russo One", sans-serif;
			font-size: 15px;
			font-weight: 400; }

		.item_center {
			label {
				text-shadow: 0 1px 0 rgba(255, 255, 255, 0.65);
				color: $gray-1500;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400;
				text-transform: uppercase;
				height: 40px;
				width: 100%;
				margin-bottom: 0;
				margin-top: 15px;
				padding: 0;
				line-height: 1.5;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				align-content: stretch; } }

		.item_footer {
			text-align: center;

			.delete {
				background-color: transparent;
				padding: 0;
				border: none;

				&:before {
					content: '';
					background-image: url("../img/svg/checkout_delete.svg");
					width: 29px;
					height: 29px;
					float: left; } }

			.model {
				font-size: 15px;
				display: block;
				overflow: hidden;
				max-height: 18px; } } } }
