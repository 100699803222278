/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Options
@import "variables";
@import "mixins";
@import 'fonts.sass';

// Bootstrap
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
// @import "../../node_modules/bootstrap/scss/tables"
@import "../../node_modules/bootstrap/scss/forms";
// @import "../../node_modules/bootstrap/scss/buttons"
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group"
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card"
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge"
// @import "../../node_modules/bootstrap/scss/jumbotron"
// @import "../../node_modules/bootstrap/scss/alert"
// @import "../../node_modules/bootstrap/scss/progress"
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close"
@import "../../node_modules/bootstrap/scss/modal";
// \@import "../../node_modules/bootstrap/scss/tooltip"
// @import "../../node_modules/bootstrap/scss/popover"
// @import "../../node_modules/bootstrap/scss/carousel"
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

/*!
 * Project
 * Copyright 2019 Serj Gonchar
 * Licensed under ISC
 */

// Style
@import "header";
@import "nav";
@import "search";
@import "main";
@import "carousel";

@import "404";
@import "gallery";
@import "footer";
@import "modal";

@import "transition";
@import "fonts";

body {
	background-color: $gray-100;
	position: relative; }

h2 {
	position: relative;
	color: $gray-2000;
	font-family: "Russo One", sans-serif;
	font-size: 35px;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 44px; }

h4 {
	color: $gray-1500;
	font-family: "Russo One", sans-serif;
	font-size: 15px;
	font-weight: 400;
	margin-bottom: 0; }

strong {
	font-family: "Open Sans", sans-serif;
	font-size: 13px;
	font-weight: 600; }

// Button Custom
.btn-custom {
	color: $gray-1400;
	font-family: "Russo One", sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 23.06px;
	text-transform: uppercase;
	background-color: $yellow;
	border-radius: $radius;
	border: none;
	padding: 13px;

	&:hover {
		background-color: $green;
		color: $white; } }

// Add Cart
.add_cart {
	text-shadow: $text-shadow;
	color: $gray-1500;
	font-family: "Russo One", sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	text-transform: uppercase;
	background-color: $yellow;
	border: solid 1px transparent;
	padding: 11px 0;
	border-radius: $radius;
	overflow: hidden;
	position: relative;
	text-align: center;
	cursor: pointer;

	&:before {
		content: attr(data-first); }

	&:after, i {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		align-content: stretch;
		opacity: 0; }

	&:after {
		content: attr(data-remove);
		background-color: $red;
		color: $white; }

	i {
		background-image: url("../img/svg/cart.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-color: $yellow; }

	input {
		display: none; }

	&:hover {
		i {
			opacity: 1; } }

	&:focus {
		outline: none; }

	&.active {
		background-color: $white;
		border-color: $gray-1500;

		&:before {
			content: attr(data-last); }

		&:hover {
			border-color: $red;

			i {
				opacity: 0; }

			&:after {
				opacity: 1; } } } }

// Add Favorites
.add_favorites {
	color: $gray-1400;
	font-family: "Open Sans", sans-serif;
	font-size: 13px;
	font-weight: 700;
	line-height: 23px;
	text-transform: uppercase;
	background-color: $white;
	border: solid 2px $gray-1500;
	padding: 6px 0;
	border-radius: $radius;
	position: relative;
	text-align: center;
	cursor: pointer;
	display: none;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

	&:before {
		content: '+';
		font-size: 28px;
		font-weight: 700;
		margin: 0 5px 0 0; }

	&:after {
		content: attr(data-first); }

	input {
		display: none; }

	&:hover {
		background-color: $gray-1400;
		color: $yellow; }

	&.active {
		background-color: $gray-1400;
		color: $yellow;

		&:before {
			content: url("../img/svg/favorites_true.svg");
			position: absolute;
			top: -2px;
			left: 30px; }

		&:after {
			content: attr(data-last); }

		&:hover {
			background-color: $red;
			color: $white;
			border-color: $red;

			&:before {
				opacity: 0; }

			&:after {
				content: attr(data-remove); } } } }

// Menu Right
.menu_right {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: stretch;
	width: 280px;
	z-index: 10;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			position: relative;
			float: right;

			label, a, button {
				color: $white;
				font-family: "Russo One", sans-serif;
				font-size: 15px;
				font-weight: 400;
				line-height: 23.06px;
				text-transform: uppercase;
				cursor: pointer;
				width: 250px;
				padding: 10px 40px;
				background-color: rgba(24,26,28,0.85);
				margin-bottom: 2px;
				float: right;
				border: none;
				text-align: left;

				input {
					display: none; }

				&:hover {
					width: 280px;
					text-decoration: none; }

				&.active {
					background-color: $yellow;
					color: $gray-1400;

					&:hover {
						width: 240px; } } }

			&:first-child {
				label {
					clip-path: polygon(20px 0%, 100% 0, 100% 100%, 0 100%); } }

			&:last-child {
				button {
					clip-path: polygon(0 0%, 100% 0, 100% 100%, 20px 100%);
					color: $yellow;
					background-color: $gray-1400; } }

			&:nth-child(3) {
				&:after {
					content: '';
					height: 4px;
					background-color: $yellow;
					width: 240px;
					position: absolute;
					right: 0;
					bottom: -2px;
					z-index: 5; } } } } }

// Menu Type Mobile
.menu_type_mobile, .nav-tabs_mobile {
	background-color: $gray-2100;

	ul {
		list-style: none;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		align-content: stretch;
		max-width: 620px;

		li {
			label {
				font-family: "Russo One", sans-serif;
				color: $gray-700;
				font-size: 13px;
				font-weight: 400;
				line-height: 23.06px;
				text-transform: uppercase;
				position: relative;
				border: solid 2px transparent;
				border-radius: $radius;
				padding: 2px 5px;
				margin: 0;
				text-align: center;

				input {
					display: none; }

				&.active {
					color: $yellow;
					border-color: $yellow; } } } } }

// Vendor Code
.vendor_code {
	color: $gray-1500;
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 23px;
	background-image: url("../img/svg/vendor_code_background_image.svg");
	background-repeat: no-repeat;
	max-width: 140px;
	height: 23px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

	&:before {
		content: attr(data-before); }

	&:after {
		content: attr(data-after);
		padding: 0 0 0 5px; } }

// Type Product
.type {
	font-family: "Open Sans", sans-serif;
	font-size: 13px;
	font-weight: 400;
	margin-top: 4px;

	&:before {
		content: '';
		width: 19px;
		height: 19px;
		float: left; }

	&[data-type="1"] {
		&:before {
			background-image: url("../img/svg/product_type_1.svg"); } }

	&[data-type="2"] {
		&:before {
			background-image: url("../img/svg/product_type_2.svg"); } }

	&[data-type="3"] {
		&:before {
			background-image: url("../img/svg/product_type_3.svg"); } }

	&:after {
		content: ' ' attr(data-text);
		margin-left: 10px; } }

// Plus Minus
.plus_minus {
	color: $gray-1600;
	background-color: $gray-200;
	font-family: "Open Sans", sans-serif;
	font-size: 20px;
	line-height: 23px;
	display: block;

	button {
		background-color: transparent;
		border: none;
		float: left;
		width: 26px;
		height: 36px;
		font-weight: 700;
		text-align: center;
		padding: 0;

		&:focus {
			outline: none; } }

	label {
		float: left;
		margin: 0; }

	input {
		background-color: $gray-200;
		width: 30px;
		border-top: 0;
		border-right: solid 1px $gray-800;
		border-bottom: 0;
		border-left: solid 1px $gray-800;
		text-align: center;
		height: 36px;
		font-weight: 600;
		padding: 0;
		border-radius: 0; } }

// Breadcrumb
nav {
	&[aria-label="breadcrumb"] {
		ol {
			background-color: transparent;
			padding: 0.75rem 0;
			margin-bottom: 12px;

			.breadcrumb-item {
				color: $gray-2000;
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 400;
				line-height: 23px;

				&:before {
					color: $gray-2000; }

				a {
					color: $gray-2000;
					border-bottom: solid 1px transparent;

					&:hover {
						text-decoration: none;
						border-color: $gray-2000; } }

				&:last-child {
					color: $gray-1000; } } } } }

// Pagination
.pagination {
	font-family: "Russo One", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 23.16px;
	text-transform: uppercase;
	width: fit-content;
	margin: 0 auto 94px auto;

	.page-item {
		.page-link {
			color: $gray-2000;
			border: solid 2px $gray-1400;
			border-radius: $radius;
			margin: 0 5px;
			padding: 8px 0 0 0;
			text-align: center;
			width: 40px;
			height: 35px;

			&:focus {
				box-shadow: none; } }

		.page-link-empty {
			color: $gray-2000;
			margin: 0 5px;
			padding: 8px 0 0 0;
			text-align: center;
			width: 35px;
			height: 35px; }

		&.active {
			.page-link {
				background-color: $gray-1400;
				color: $yellow; } }

		&:first-child {
			.page-link {
				span {
					&:before {
						content: url("../img/svg/pagination_prev.svg"); } } } }

		&:last-child {
			.page-link {
				span {
					&:before {
						content: url("../img/svg/pagination_next.svg"); } } } } } }

// Progress Bar
.dws-progress-bar {
	position: relative;

	.progress-percentage {
		color: $gray-1500;
		font-family: "Open Sans", sans-serif;
		font-size: 18px;
		font-weight: 700;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		align-content: stretch;
		z-index: 5; }

	.progress-text {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		align-content: stretch;

		&:after {
			content: '';
			width: 79px;
			height: 79px;
			background-color: $white;
			position: absolute;
			border-radius: 100%;
			border: solid 8px #efefef; } }

	canvas {
		position: relative; } }

// Price
.product_price {
	float: left;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-content: stretch;

	.price {
		color: $gray-1500;
		font-family: "Russo One", sans-serif;
		font-size: 23px;
		font-weight: 400;
		line-height: 23px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: flex-start;
		align-content: stretch;

		&:after {
			color: $gray-1500;
			font-family: "Open Sans", sans-serif;
			font-size: 12px;
			font-weight: 600;
			line-height: 23px; } }

	.availability {
		display: none;
		color: $green;
		font-family: "Open Sans", sans-serif;
		font-size: 13px;
		font-weight: 600;

		span {
			font-weight: 700; } } }

// Range
.slider-container {
	width: 100%;
	background-color: transparent;

	.back-bar {
		height: 17px;
		border: solid 1px #202020;
		background-color: #434343;
		background-image: none;
		border-radius: 10px;

		.selected-bar {
			z-index: 2;
			background-color: $gray-1500;
			background-image: none; }

		.pointer {
			top: 0;
			background-image: url("../img/svg/range_pointer.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-color: $yellow;
			border: none;
			width: 40px;
			height: 15px;
			box-shadow: 1px 0 8px rgba(0, 0, 0, 0.5);
			cursor: pointer; }

		.clickable-dummy {
			background-image: url("../img/svg/range.svg");
			border-radius: 10px; }

		.pointer-label {
			display: none; } }

	.scale {
		display: none; } }

// Pointer Rage
.pointer_container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;

	label {
		margin: 0 0 7px 0; } }

.pointer_range {
	position: relative;
	width: 49px;
	color: $gray-1600;
	font-family: "Open Sans", sans-serif;
	font-size: 14px;
	font-weight: 700;
	font-style: italic;
	line-height: 23px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

	&:before, &:after {
		position: absolute;
		color: $white;
		font-family: "Open Sans", sans-serif;
		font-size: 12px;
		font-weight: 700;
		font-style: italic;
		line-height: 23px; }

	&:before {
		content: attr(data-before);
		left: -30px; }

	&:after {
		content: attr(data-after);
		right: -30px; }

	input {
		background-color: $gray-200;
		width: 49px;
		height: 30px;
		border: none;
		text-align: center;

		&:focus {
			outline: none; } } }

// Class
.p7_5px {
	padding: 0 7.5px; }

// Graphic Element
.graphic_elements {
	border-top: 0;
	border-bottom: 0;
	position: absolute;
	top: 30px;
	max-width: 720px;
	margin: auto;

	// Type 1
	&.type_1 {
		.left {
			width: 1px;
			background-color: $yellow;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;

			.top {
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					top: 0;
					left: 0;
					width: 25px;
					height: 1px; } }

			.bottom {
				position: absolute;
				bottom: 0;
				left: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: 0;
					left: 0;
					// width: 90px // 145
					height: 1px; }

				&:after {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: -3px;
					// left: 90px // 145
					width: 7px;
					height: 7px;
					border-radius: 100%; } } }

		.right {
			width: 1px;
			background-color: $yellow;
			position: absolute;
			top: 0;
			// bottom: 80px
			right: 0;

			.top {
				position: absolute;
				top: 0;
				right: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					top: 0;
					right: 0;
					width: 25px;
					height: 1px; } }

			.bottom {
				position: absolute;
				bottom: 0;
				right: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: 0;
					right: 0;
					// width: 90px // 145
					height: 1px; }

				&:after {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: -3px;
					// right: 90px // 145
					width: 7px;
					height: 7px;
					border-radius: 100%; } } } }

	// Type 2
	&.type_2 {
		.left {
			width: 1px;
			background-color: $yellow;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;

			.top {
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					top: 0;
					left: 0;
					height: 1px; } }

			.bottom {
				position: absolute;
				bottom: 0;
				left: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: 0;
					left: 0;
					// width: 90px // 145
					height: 1px; }

				&:after {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: -3px;
					// left: 90px // 145
					width: 7px;
					height: 7px;
					border-radius: 100%; } } }

		.right {
			width: 1px;
			background-color: $yellow;
			position: absolute;
			top: 0;
			// bottom: 80px
			right: 0;

			.top {
				position: absolute;
				top: 0;
				right: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					top: 0;
					right: 0;
					// width: 25px
					height: 1px; } }

			.bottom {
				position: absolute;
				bottom: 0;
				right: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: 0;
					right: 0;
					// width: 90px // 145
					height: 1px; }

				&:after {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: -3px;
					// right: 90px // 145
					width: 7px;
					height: 7px;
					border-radius: 100%; } } } }

	// Type 3
	&.type_3 {
		.left {
			width: 1px;
			background-color: $yellow;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;

			.top {
				position: absolute;
				top: 0;
				left: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					top: 0;
					left: 0;
					// width: 25px
					height: 1px; } }

			.bottom {
				position: absolute;
				bottom: 0;
				left: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: 0;
					left: 0;
					// width: 90px // 145
					height: 1px; }

				&:after {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: -3px;
					// left: 90px // 145
					width: 7px;
					height: 7px;
					border-radius: 100%; } } }

		.right {
			width: 1px;
			background-color: $yellow;
			position: absolute;
			top: 0;
			// bottom: 80px
			right: 0;

			.top {
				position: absolute;
				top: 0;
				right: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					top: 0;
					right: 0;
					// width: 25px
					height: 1px; } }

			.bottom {
				position: absolute;
				bottom: 0;
				right: 0;

				&:before {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: 0;
					right: 0;
					// width: 90px // 145
					height: 1px; }

				&:after {
					content: '';
					background-color: $yellow;
					position: absolute;
					bottom: -3px;
					// right: 90px // 145
					width: 7px;
					height: 7px;
					border-radius: 100%; } } } } }
@import "mediaQuery";
