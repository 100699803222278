$xs: 320px;
/* Small devices (phones, 320px and up) */
@media (min-width: $xs) and (max-width: 480px) {
	// Style
	h2 {
		line-height: 40px; }

	.menu_type_mobile {
		padding: 58px 0 17px 0;
		margin-left: 30px;
		margin-right: 30px;
		margin-bottom: 10px;
		background-color: transparent;

		ul {
			padding: 0 7px;
			li {
				label {
					font-size: 18px; } } } }

	nav {
		&[aria-label="breadcrumb"] {
			padding-top: 65px; } }

	// Graphic Element
	.graphic_elements {
		display: none; }


	// Header
	header {
		// List
		ul.top_list {
			display: none; }

		// Cart
		.cart_and_review {
			.cart_header {
				margin: 15px 15px 0 15px;
				padding-bottom: 15px;

				button {
					position: absolute; } }

			.cart_body {
				padding: 0 15px;

				.post {
					.post_image {
						max-width: 100px;
						margin: auto auto 15px auto; }

					.product_name {
						text-align: center; }

					.plus_minus {
						float: left;
						margin-top: 15px; } } }

			.cart_footer {
				padding: 0 15px;

				.total_price {
					padding: 5px 0;
					margin-bottom: 15px; }

				.cart_btn {
					width: 100%;

					&.cart_btn_order {
						margin: 0 0 15px 0; }

					&.cart_btn_shopping {
						margin: 0; } } }

			// Write a Feedback
			.rating_content {
				display: flex;
				flex-direction: column; }

			.radio_rating {
				order: 2;
				margin: 5px 0 15px 0;

				li {
					width: 35px;
					height: 33px;
					background-size: 35px;
					margin: 0 5px 0 0; }

				&:after {
					top: 8px; } }

			.info {
				margin: 0; } } }

	// Main
	main {
		// Carousel
		.owl-carousel_brand_links {
			.owl-stage-outer {
				max-width: calc(100% - 60px); } }

		.owl-carousel_action_products {
			.owl-item {
				padding: 5px; } }

		.owl-carousel_comparison {
			.owl-nav {
				right: -15px;
				left: -15px;

				button {
					width: 15px; } } }

		section {
			// Home
			&.home {
				.filter {
					position: relative;
					top: 0;
					right: 0;
					left: 0;
					width: 100%;
					flex-direction: column;

					.filter_content {
						width: 100%;
						margin: auto; } }

				.car_type {
					padding: 15px;
					margin-bottom: 100px; } }

			// Sizes
			&.sizes {
				background-image: url("../img/png/sizes_background_mobile.png");
				img {
					max-width: 50%;
					bottom: -75px;
					left: 30%; } }
			// Single
			&.single {
				.product_right_content {
					.specifications {
						table {
							&:before {
								padding: 0 0 0 15px; }

							tr {
								td {
									padding: 0 0 0 15px;

									&:first-child {
										width: 180px; } } } } } }

				.single_reviews {
					.reviews_header {
						.add_reviews {
							width: 100%; }

						.info {
							margin: 10px 0 25px 0;
							font-size: 16px; }

						.progress_content {
							flex-direction: row-reverse; }

						.progress_text {
							font-size: 15px;
							text-align: right; } }

					table {
						tr {
							td {
								font-size: 10px;

								&:first-child {
									width: 170px; } } } }

					.reviews_content {
						time {
							float: left; } } } }
			// Catalog
			&.catalog {
				.catalog_pagination_bottom {
					float: none;
					margin: 15px auto; } }
			// Checkout
			&.checkout {
				.name {
					line-height: 1.2; }

				.vendor_code {
					background-image: none;
					justify-content: flex-start; }

				.plus_minus {
					width: 100%;

					button, label {
						width: 33.3%; }

					input {
						width: 100%; } } }
			// Selection
			&.selection {
				.options {
					ul {
						flex-direction: column;
						align-items: flex-start; } }

				.item {
					&.item_left {
						.value {
							width: 109px;
							margin: 0 auto 25px auto;

							&:after {
								width: 38px;
								right: -10px;
								bottom: -20px; } } } } } } }
	// Footer
	footer {
		padding: 15px 0 0 0;

		// List
		ul {
			margin-bottom: 15px;

			li {
				a, i {
					img {
						margin: 0 10px 0 0; } } } }

		// Copyright
		.copyright {
			text-align: left; } }

	.rating {
		.progress {
			height: 8px;
			width: 45px;

			.mask {
				height: 8px;
				background-size: 9px; }

			.scale {
				height: 8px; } } }

	.products_container {
		.carousel_item {
			.item_header {
				img {
					max-width: 185px;
					max-height: 153px; } }
			.product_info {
				.content_border:before {
					transform: scaleX(0.8); }
				.content_right {
					.rating::after {
						content: ""; } } } } }

	.product_price {
		.price {
			min-height: 69px; } }

	main {
		section.home {
			background-image: url("../img/png/home_background_mobile.png");

			.car_type {
				margin-bottom: 33px; }

			.filter {
				.filter_content {
					min-height: 430px; } } } }

	#filter_type_1 {
		margin: auto;
		border-radius: 0;
		margin-left: 36px;
		margin-right: 36px;

		.filter_content:nth-child(2) {
			display: none; } } }
