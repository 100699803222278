$sm: 737px;
/* Small devices (tablets, 737px and up) */
@media (min-width: $sm) {

	// Style
	.menu_type_mobile {
		padding: 135px 0 20px 0;

		ul {
			padding: 0 15px;

			li {
				label {
					width: 145px; } } } }

	nav {
		&[aria-label="breadcrumb"] {
			padding-top: 135px; } }

	// Graphic Element
	.graphic_elements {
		display: block;
		right: -65px;
		bottom: 335px;
		left: -65px;

		// Type 1
		&.type_1 {
			.left {
				.bottom {
					&:before {
						width: 145px; }

					&:after {
						left: 145px; } } }

			.right {
				bottom: 50px;

				.bottom {
					&:before {
						width: 145px; }

					&:after {
						right: 145px; } } } }

		// Type 2
		&.type_2 {
			.left {
				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 145px; }

					&:after {
						left: 145px; } } }

			.right {
				bottom: 0;

				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 335px; }

					&:after {
						right: 335px; } } } }

		// Type 3
		&.type_3 {
			.left {
				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 85px; }

					&:after {
						left: 85px; } } }

			.right {
				bottom: 130px;

				.top {
					&:before {
						width: 200px; } }

				.bottom {
					&:before {
						width: 285px; }

					&:after {
						right: 285px; } } } } }

	// Header
	header {
		.row:first-child {
			height: 65px; }

		&.slim {
			.row:first-child {
				height: 8px;
				opacity: 0; }

			.logo {
				bottom: -66px; }

			ul.top_list.top_list_focus li a {
				padding-top: 7px;
				padding-bottom: 15px; } }

		// List
		ul.top_list {
			display: block;
			columns: 2;

			li {
				a {
					padding: 0; } } }

		// Logo
		.logo {
			&:before {
				height: 104px; } }

		// Link Buttons
		.links_button {
			li {
				a {
					img {
						margin: 0 10px 0 0; } } } }

		// Cart
		.cart_and_review {
			.cart_header {
				margin: 30px 30px 0 30px;
				padding-bottom: 30px;

				button {
					position: relative; } }

			.cart_body {
				padding: 0 30px;

				.post {
					.post_image {
						max-width: 100%;
						margin: 0; }

					.product_name {
						text-align: left; }

					.plus_minus {
						float: right;
						margin-top: 0; } } }

			.cart_footer {
				padding: 0 30px;

				.total_price {
					padding: 25px 0;
					margin-bottom: 30px; }

				.cart_btn {
					width: 46%;

					&.cart_btn_order {
						margin: 0 12px 0 0; }

					&.cart_btn_shopping {
						margin: 0 0 0 12px; } } }

			// Write a Feedback
			.rating_content {
				display: block;

				.radio_rating {
					order: 1;
					margin: 0;

					li {
						width: 12px;
						height: 11px;
						background-size: 13px;
						margin: 0; }

					&:after {
						top: -4px; } }

				.info {
					margin: -4px 0 0 52px; } } } }

	// Main
	main {
		// Carousel
		.owl-carousel_brand_links {
			.item {
				a {
					padding: 0 15px; } } }

		.owl-carousel_action_products {
			.owl-item {
				padding: 0; } }

		.owl-carousel_comparison {
			.owl-nav {
				right: -30px;
				left: -30px;

				button {
					width: 30px; } } }

		section {
			// Home
			&.home {
				height: 100vh;

				.links_top {
					a {
						&:first-child {
							left: -40px; }

						&:last-child {
							right: -40px; } } }

				.filter {
					position: absolute;
					top: 15px;
					right: 15px;
					left: 15px;
					width: auto;
					flex-direction: row;

					.filter_content {
						min-width: 320px;
						max-width: 320px;
						margin: 0 15px; } }

				.car_type {
					position: absolute;
					right: 0;
					bottom: 270px;
					left: 0;
					margin-bottom: 0; } }

			// Sizes
			&.sizes {
				img {
					max-width: 35%;
					bottom: -55px;
					left: 15%; } }
			// Single
			&.single {
				.product_right_content {
					.specifications {
						table {
							&:before {
								padding: 0 0 0 25px; }

							tr {
								td {
									padding: 0 0 0 25px;

									&:first-child {
										width: 233px; } } } } } }

				.single_reviews {
					.reviews_header {
						.add_reviews {
							width: auto; }

						.info {
							font-size: 18px;
							margin: 3px 0;
							max-width: 312px;
							line-height: 1.0;
							text-align: right;
							float: right; }

						.progress_content {
							flex-direction: row;
							margin-top: 15px; }

						.progress_text {
							font-size: 18px;
							text-align: left;

							span {
								font-size: 25px; } } }

					table {
						margin: 15px 0 0 0;

						tr {
							td {
								&:first-child {
									width: auto; } } } }

					.reviews_content {
						&:before {
							content: ''; } } } }
			// Catalog
			&.catalog {
				.filter_select {
					select {
						height: 36px; } }

				.sidebar_filter_toggle {
					height: 36px;

					margin: 0 10px 0 0;

					&:before {
						margin: auto; }

					&:after {
						display: none; } } }
			// Checkout
			&.checkout {
				.name {
					line-height: 1.5;
					max-width: 200px; }

				.vendor_code {
					margin-top: 15px;
					background-image: url("../img/svg/vendor_code_background_image.svg");
					justify-content: center; }

				.checkout_footer {
					.value {
						&:before {
							display: none; } }

					button {
						max-width: 230px; } } }

			// Comparison
			&.comparison {
				.table_list {
					li {
						&:nth-child(3) {
							margin: 63px 0 0 0; } } } }

			// Selection
			&.selection {
				.options {
					ul {
						flex-direction: row;
						align-items: center; } } } } }

	// Footer
	footer {
		padding: 60px 0 0 0;

		// List
		ul {
			li {
				a, i {
					img {
						position: absolute;
						margin: 0; } } } } } }
