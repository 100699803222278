// Color system
$gray-100:	#fefefe;
$gray-200:	#ebebeb; // Used
$gray-300:	#ededed;
$gray-400:	#e8e8e8; // Used
$gray-500:	#e8e8e8; // Used (ededed)
$gray-600:	#d7d7d7; // Used
$gray-700:	#d3d3d3; // Used
$gray-800:	#b0b1b1; // Used
$gray-900:	#858585; // Used
$gray-1000:	#888888; // Used
$gray-1100:	#7a7a7a;
$gray-1200:	#6a6a6a;
$gray-1300:	#363e42; // Used
$gray-1400:	#2b363c; // Used
$gray-1500:	#313336; // Used
$gray-1600:	#26292c; // Used
$gray-1700:	#222427;
$gray-1800:	#1e2222;
$gray-1900:	#191d1f; // Used
$gray-2000:	#181a1c; // Used
$gray-2100:	#17191b;
$gray-2200:	#111517; // Used
$gray-2300:	#0e0f13;

$yellow: #f8c311;
$green: #00b208;
$red:	#ff0000;

// Options
$radius: 		3px;
$shadow: 		0 0 59px rgba(0, 0, 0, 0.75);
$text-shadow: 	0 1px 0 rgba(255, 255, 255, 0.65);

// Grid
// 1140
$container-max-widths: (sm: 540px, md: 620px, lg: 960px, xl: 1240px);

